import { createBrowserRouter } from "react-router-dom"
//import Onboarding from "../features/onboarding/Onboarding"
import ErrorPage from "../features/error_page/ErrorPage"
import SplashPage from "../features/splash/SplashPage"

const Routes = createBrowserRouter([
  {
    path: "/",
    element: <SplashPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/splashPage",
    element: <SplashPage />,
  },
])
export default Routes
