import { TokenStorageModel } from "./appTokensStore"

export interface PersistentStorage {
  getData(): TokenStorageModel
  setData(newData: TokenStorageModel): void
  clean(): void
}
export const persistentStorage = (): PersistentStorage => {
  return new PlatformStorage(localStorage)
}

class PlatformStorage implements PersistentStorage {
  constructor(storage: Storage) {
    this.platformLocalStorage = storage
  }

  private storageKey = "LocalCurrentUser"
  private platformLocalStorage: Storage
  private defaultData: TokenStorageModel = {
    applicationToken: null,
    refreshToken: null,
    userId: null,
  }

  getData(): TokenStorageModel {
    const currentData = this.platformLocalStorage.getItem(this.storageKey)
    if (currentData === null) {
      return this.defaultData
    } else {
      return JSON.parse(currentData)
    }
  }
  setData(newData: TokenStorageModel): void {
    this.platformLocalStorage.setItem(this.storageKey, JSON.stringify(newData))
  }
  clean(): void {
    this.platformLocalStorage.removeItem(this.storageKey)
  }
}
