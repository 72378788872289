import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice"
import { swaggerSplitApi } from "./data/swaggerApi"
import tokenStorage from "./data/appTokensStore"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    tokenStorage: tokenStorage,
    [swaggerSplitApi.reducerPath]: swaggerSplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(swaggerSplitApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
