import "./App.css"
import { RouterProvider } from "react-router-dom"
import Routes from "./routes/Routes"
//import { initializeApp } from "firebase/app"
//import { getAnalytics } from "firebase/analytics"
//import { firebaseConfig } from "./firebaseConfig"
import { useEffect } from "react"

function App() {
  useEffect(() => {
    // const app = initializeApp(firebaseConfig)
    // const analytics = getAnalytics(app);
  }, [])
  return <RouterProvider router={Routes} />
}

export default App
