import React from "react"
import styles from "./SplashPage.module.css"
import illustration from "../../assets/illustration.svg"

const SplashPage: React.FC = () => {
  const url = import.meta.env.VITE_API_BASE_URL
  console.log(`url is ${url}`)
  return (
    <>
      <header className={styles.header}>
        <div className={styles.header__inner}>
          <div className={styles.banner} />
          <div className={styles.banner} />
          <div className={styles.banner} />
        </div>
        <h1 className={styles.header__title}>Tribe</h1>
        <p className={styles.header__text}>САЙТ В РАЗРАБОТКЕ!</p>
      </header>
      <main className={styles.main}>
        <img alt={"illustration"} src={illustration} />
      </main>
    </>
  )
}
export default SplashPage
