import { fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import {
  selectRefreshToken,
  userLogout,
  setUserCredentials,
  selectApplicationToken,
} from "./appTokensStore"
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query"
import { RootState } from "../store"
import { RefreshTokensApiResponse } from "./tribeSwaggerApi"

const baseQuery = fetchBaseQuery({
  baseUrl: "https://dev.tribual.ru",
  jsonContentType: "application/json",
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-Type", "application/json")
    const token = selectApplicationToken(getState() as RootState)
    if (token === null) {
      return headers
    } else {
      headers.set("authorization", `Bearer ${token}`)

      return headers
    }
  },
})
export const refreshBaseQuery: BaseQueryFn<
  FetchArgs | string,
  unknown,
  FetchBaseQueryError
> = async (args, api, extra) => {
  //делаем запрос
  let result = await baseQuery(args, api, extra)
  const refreshToken = selectRefreshToken(api.getState() as RootState)
  //а если что то пошло не так и нас послали нахер
  if (result.error && result.error.status === 401) {
    // провереям а был ли мальч... тоесть токен
    if (refreshToken === null) {
      // если нет то нет
      return result
    }
    // обновляем данные
    const refreshResult = await baseQuery(
      {
        url: "/api/v1/auth/token/refresh",
        headers: { Authorization: refreshToken },
      },
      api,
      extra,
    )

    if (refreshResult.data) {
      const newTokens = refreshResult.data as RefreshTokensApiResponse
      api.dispatch(
        setUserCredentials({
          applicationToken: newTokens.access_token ?? null,
          refreshToken: newTokens.refresh_token ?? null,
          userId: newTokens.user_id ?? null,
        }),
      )
      // повторяем запрос с новыми данными
      result = await baseQuery(args, api, extra)
    } else {
      // ну тут мои полномочия все - выйди и зайди как положено
      api.dispatch(userLogout())
    }
  }
  return result
}
