import { createSlice } from "@reduxjs/toolkit"
import { PayloadAction } from "@reduxjs/toolkit"
import { persistentStorage } from "./persistentStorage"
import { RootState } from "../store"

export interface TokenStorageModel {
  applicationToken: string | null
  refreshToken: string | null
  userId: number | null
}

const externalStorage = persistentStorage()

export const tokenStorageSlice = createSlice({
  name: "tokenStorage",
  initialState: () => externalStorage.getData(),
  reducers: {
    setUserCredentials: (state, action: PayloadAction<TokenStorageModel>) => {
      const { payload } = action
      state.applicationToken = payload.applicationToken
      state.refreshToken = payload.refreshToken
      state.userId = payload.userId
      externalStorage.setData(payload)
    },
    userLogout: (state) => {
      state.applicationToken = null
      state.refreshToken = null
      state.userId = null
      externalStorage.clean()
    },
    updateApplicationToken: (state, action: PayloadAction<string>) => {
      state.applicationToken = action.payload
      const newState = { ...state, applicationToken: action.payload }
      externalStorage.setData(newState)
    },
  },
})

export const selectUserId = (state: RootState) => state.tokenStorage.userId
export const selectApplicationToken = (state: RootState) =>
  state.tokenStorage.applicationToken
export const selectRefreshToken = (state: RootState) =>
  state.tokenStorage.refreshToken
export const { setUserCredentials, updateApplicationToken, userLogout } =
  tokenStorageSlice.actions
export default tokenStorageSlice.reducer
